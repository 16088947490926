import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextFieldElement } from "react-hook-form-mui";

import { Alert, Box, Button, Tab, Tabs } from "@mui/material";
import HooksTabPanel from "./HooksTabPanel";
import ScriptBasicHelperInfo from "./ScriptBasicHelperInfo";
import JavascriptHookInfo from "./JavascriptHookInfo";
import WebHookInfo from "./WebHookInfo";

function TabContent({ tab, onChange, setShowHook, showHook }) {
  return (
    <>
      <Box hidden={tab !== 0}>
      <Grid container hidden={tab !== 0} >
        <Grid item xs={12}>
          <ScriptBasicHelperInfo />
        </Grid>
        <Grid item xs={12}>
          <TextFieldElement
            id="selectorUsername"
            name="selectorUsername"
            label="Username selector"
            fullWidth
            variant="standard"
            helperText="The DOM selector for the username field."
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldElement
            id="selectorEmail"
            name="selectorEmail"
            label="Email address selector"
            fullWidth
            variant="standard"
            helperText="The DOM selector for the email field."
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldElement
            id="selectorPassword"
            name="selectorPassword"
            label="Password selector"
            fullWidth
            variant="standard"
            helperText="The DOM selector for the password field."
            onChange={onChange}
          />
        </Grid>
      </Grid>
      </Box>


      <Box hidden={tab !== 1}>
      <Grid container hidden={tab !== 1}>
        
          <JavascriptHookInfo />

        </Grid>


      </Box>

      <Box hidden={tab !== 2}>
      <Grid container hidden={tab !== 2}>
        <Grid item xs={12}>
        <WebHookInfo />
        </Grid>
        <Grid item xs={12}>
          
          <TextFieldElement
            id="webhookURL"
            name="webhookURL"
            label="Webhook URL"
            fullWidth
            variant="standard"
            helperText="The URL to receive the password. Note: when this is set the DOM selectors are ignored."
            onChange={onChange}
          />
        </Grid>
      </Grid>
      </Box>
    </>
  );
}

function a11yProps(index) {
  return {
    id: `hooks-tab-${index}`,
    "aria-controls": `hook-tabpanel-${index}`,
  };
}

export default function WebsiteDetails({ setScriptGenerated ,data, updateType }) {
  const [showHook, setShowHook] = React.useState(false);

  const [activeTab, setActiveTab] = React.useState(()=>{
   
    if (data?.type === "basic") {
      return 0;
    } else if (data?.type === "callback") {
      return 1;
    } else if (data?.type === "webhook") {
      return 2;
    } else {
      return 0;
    }

  });

  const onChange = () => {
    setScriptGenerated(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    updateType(newValue === 1 ? "callback" : newValue === 2 ? "webhook" : "basic");
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Login details
      </Typography>

      <Tabs
        variant="fullWidth"
        value={activeTab}
        onChange={handleTabChange}
        aria-label="hook tab"
      >
        <Tab label="Basic" {...a11yProps(0)} />
        <Tab label="JavaScript callback" {...a11yProps(1)} />

        <Tab label="Webhook" {...a11yProps(2)} />
      </Tabs>



      <TabContent
        tab={activeTab}
        onChange={onChange}
        setShowHook={setShowHook}
        showHook={showHook}
      />
    </React.Fragment>
  );
}
