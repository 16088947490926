import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import WebsiteDetails from "../Components/WebsiteDetails";
import { FormContainer, useForm } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import { updateScript } from "../api/scripts";
import { auth } from "../api/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import HeaderAppBar from "../Components/Common/HeaderAppBar";
import ApplicationDetails from "../Components/ApplicationDetails";
import NewScriptCompletionInfo from "../Components/NewScriptCompletionInfo";
import GenerateScriptActionInfo from "../Components/GenerateScriptActionInfo";
import LoadingScreenBackdrop from "../Components/Common/LoadingScreenBackdrop";

const steps = ["Application details", "Auth method", "Your script"];

function getStepContent(
  step,
  scriptGenerated,
  setScriptGenerated,
  saveForm,
  data,
  handleTabType,
  avatarUpload
) {
  switch (step) {
    case 0:
      return (
        <ApplicationDetails
          scriptGenerated={scriptGenerated}
          setScriptGenerated={setScriptGenerated}
          saveForm={saveForm}
          data={data}
          avatarUpload={avatarUpload}
        />
      );
    case 1:
      return (
        <WebsiteDetails
          scriptGenerated={scriptGenerated}
          setScriptGenerated={setScriptGenerated}
          saveForm={saveForm}
          data={data}
          avatarUpload={avatarUpload}
        />
      );
    case 2:
      return (
        <GenerateScriptActionInfo
          scriptGenerated={scriptGenerated}
          setScriptGenerated={setScriptGenerated}
          saveForm={saveForm}
          data={data}
          avatarUpload={avatarUpload}
        />
      );
    default:
      throw new Error("Unknown step");
  }
}

export default function NewScript() {
  const navigate = useNavigate();
  const [user, loadingAuth, error] = useAuthState(auth);

  const { handleSubmit } = useForm();

  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [scriptGenerated, setScriptGenerated] = React.useState(false);

  React.useEffect(() => {
    if (error) {
      alert(error.message);
    }
    if (loadingAuth) return;
    if (!user) return navigate("/login");
  }, [error, loadingAuth, navigate, user]);

  const validate = () => {
    if (activeStep === 0) {
      return true;
    }
    if (activeStep === 1) {
      return true;
    }
    if (activeStep === 2) {
      return true;
    }
  };

  const handleNext = (data) => {
    if (validate()) {
      const newData = { ...formData, ...data };

      setFormData(newData);

      if (activeStep === steps.length - 2) {
    
        handleFormSubmit(newData);
      }
    }

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleTabType = (newValue) => {
    const newData = { ...formData, type: newValue };
    setFormData(newData);
    setScriptGenerated(false);
  };

  const avatarUpload = (fileURL) => {
    const newData = { ...formData, avatar: fileURL };
    setFormData(newData);
    setScriptGenerated(false);
  };

  const handleFormSubmit = async (data) => {
    setLoading(true);
    setScriptGenerated(false);
    const avatar = formData.avatar;
    const type = formData.type;
    const newData = { ...formData, ...data };
    newData.avatar = avatar;
    newData.type = type;

    for (const key in newData) {
      if (!newData[key] && newData[key] !== null) {
        delete newData[key];
      }
    }
    try {
      const script = await updateScript(newData);
      setFormData({ ...newData, ...script });
    } catch (error) {
      console.log(error);
    }
    setScriptGenerated(true);
    setLoading(false);
  };
  return (
    <>
      <HeaderAppBar
        closeAction={() => {
          navigate("/");
        }}
      />

      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <LoadingScreenBackdrop open={loading} />
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, borderRadius: 2 }}
        >
          <Typography component="h1" variant="h4" align="center">
            Create a web script
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel> {label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <NewScriptCompletionInfo />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <FormContainer onSuccess={handleNext}>
                {getStepContent(
                  activeStep,
                  scriptGenerated,
                  setScriptGenerated,
                  handleSubmit(handleFormSubmit),
                  formData,
                  handleTabType,
                  avatarUpload
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Back
                    </Button>
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, ml: 1 }}
                    disabled={
                      activeStep === steps.length - 1 && !scriptGenerated
                    }
                  >
                    {activeStep === steps.length - 1 ? "Done" : "Next"}
                  </Button>
                </Box>
              </FormContainer>
            </React.Fragment>
          )}
        </Paper>
      </Container>
    </>
  );
}
