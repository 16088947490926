import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  TableContainer,
  Tooltip,
  Typography,
} from "@mui/material";

import MaterialReactTable from "material-react-table";
import { deleteScript, scriptListener } from "../api/scripts";
import DeleteScriptDialog from "../Components/DeleteScriptDialog";
import LoadingScreenBackdrop from "./Common/LoadingScreenBackdrop";
import EditScript from "../Pages/EditScript";
import { Delete, Edit } from "@mui/icons-material";

function ScriptsTable({ editable, editableDone }) {
  const scriptListenerUnsub = useRef(null);
  const [showEditScript, setShowEditScript] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [updating] = useState(false);

  const [currentRow, setCurrentRow] = useState(null);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  //A little callback to get the dashboard usernam
  const fetchRows = useCallback(async () => {
    try {
      scriptListenerUnsub.current = scriptListener((data) => {
        setRows(data);
      });
    } catch (err) {
      console.error(err);
    }
  }, []);

  //Call fetchusername when user changes, make sure dependency array is correct
  useEffect(() => {
    fetchRows();

    return () => {
      if (
        scriptListenerUnsub.current &&
        typeof scriptListenerUnsub.current === "function"
      ) {
        scriptListenerUnsub.current();
      }
    };
  }, [fetchRows]);

  const typeValues = {
    callback: "Javascript Callback",
    webhook: "Webhook",
    basic: "Basic",
  };
  const columns = useMemo(
    () => [
      {
       //accessorKey: "websiteName", //access nested data with dot notation
        header: "Application",
        accessorFn: (row) => `${row.websiteName.toLowerCase()}`,
        Cell: ({ cell, row, renderedCellValue }) => {
          let img = row.original.avatar || "forghetti-app-icon-192px.png";

          return <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
           <Avatar src={img} width={42} sx={{ opacity: row.original.avatar ? 1 : 0.2}}/>
          {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
          <span>{row.original.websiteName}</span>
        </Box>

        
        },
      },
      {
        accessorKey: "logins", //normal accessorKey
        header: "Logins",
      },
      {
        accessorFn: (row) => `${typeValues[row.type]|| typeValues['basic']}`,
        accessorKey: "type", //normal accessorKey
        header: "Type",
        // Cell: ({ cell }) => {
        //   return typeValues[cell.getValue()];
        // },
      },
      {
        accessorFn: (row) => { return row.created.toDate().getTime() || 0} ,
        enableClickToCopy: false,
        header: "Created",
        Cell: ({ cell }) => {
          return new Date(cell.getValue()).toLocaleString();
        },
      },
    ],
    []
  );

  const handleDeleteRow = (row) => {
    setShowDeleteDialog(true);
    setCurrentRow(row.original);
  };
  const handleDelete = () => {
    setShowDeleteDialog(false);
    deleteScript(currentRow);
    setCurrentRow(null);
  };
  const handleEditRow = (row) => {
    setShowEditScript(true);
    console.log("row", row)
    setCurrentRow(row.original);
  };

  return (
    <>
      <LoadingScreenBackdrop open={updating} />

      <TableContainer component={Paper}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
          }}
        >
          <Typography noWrap variant="h6" sx={{ py: 2, pl: 2 }}>
            Scripts
          </Typography>
          {editable && (
            <Button
              variant="contained"
              sx={{ backgroundColor: "primary", py: 1, px: 2, mr: 2 }}
              onClick={() => navigate("/new")}
            >
              Add script
            </Button>
          )}
        </Box>
        {editableDone && (
          <MaterialReactTable
            columns={columns}
            data={rows}
            enableBottomToolbar={false}
            enableFilterMatchHighlighting={true}
            enableGlobalFilter={true}
            enableRowActions={editable}
            enableClickToCopy={true}
            enableResizing={true}
            positionActionsColumn="last"
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: "flex" }}>
                <Tooltip arrow placement="left" title="Edit">
                  <IconButton onClick={() => handleEditRow(row)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow placement="right" title="Delete">
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteRow(row)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          />
        )}
      </TableContainer>
      {showEditScript && (
        <EditScript
          show={showEditScript}
          setShow={setShowEditScript}
          data={currentRow}
        />
      )}
      {showDeleteDialog && (
        <DeleteScriptDialog
          open={showDeleteDialog}
          setOpen={setShowDeleteDialog}
          deleteAction={handleDelete}
        ></DeleteScriptDialog>
      )}
    </>
  );
}
export default ScriptsTable;
