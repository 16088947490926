import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import WebsiteDetails from "../Components/WebsiteDetails";
import { FormContainer, useForm } from "react-hook-form-mui";
import { Backdrop, Divider, Tab, Tabs } from "@mui/material";
import { updateScript } from "../api/scripts";
import HeaderAppBar from "../Components/Common/HeaderAppBar";
import ApplicationDetails from "../Components/ApplicationDetails";
import GenerateScriptActionInfo from "../Components/GenerateScriptActionInfo";

function TabContent({
  tab,
  scriptGenerated,
  setScriptGenerated,
  tabType,
  data,
  avatarUpload,
  defaultAvatar
}) {
  return (
    <>
      <Box hidden={tab !== 0}>
        <ApplicationDetails
          scriptGenerated={scriptGenerated}
          setScriptGenerated={setScriptGenerated}
          data={data}
          avatarUpload={avatarUpload}
            defaultAvatar={defaultAvatar}
        />
      </Box>
      <Box hidden={tab !== 1}>
        <WebsiteDetails
          scriptGenerated={scriptGenerated}
          setScriptGenerated={setScriptGenerated}
          updateType={tabType}
          data={data}
          avatarUpload={avatarUpload}
            defaultAvatar={defaultAvatar}
        />
      </Box>
    </>
  );
}

function a11yProps(index) {
  return {
    id: `hooks-tab-${index}`,
    "aria-controls": `hook-tabpanel-${index}`,
  };
}

export default function EditScript({ show, setShow, data }) {
  const [activeTab, setActiveTab] = React.useState(0);
  const [formData, setFormData] = React.useState(data);
  const [, setLoading] = React.useState(false);

  const { handleSubmit } = useForm();
  const [scriptGenerated, setScriptGenerated] = React.useState(true);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleTabType = (newValue) => {
    const newData = { ...formData, type: newValue };
    setFormData(newData);
    setScriptGenerated(false);
  };

  const avatarUpload = (fileURL) => {
    const newData = { ...formData, avatar: fileURL };
    setFormData(newData);
    setScriptGenerated(false);
  };

  const handleFormSubmit = async (data) => {
    setLoading(true);
    setScriptGenerated(false);

    const newData = { ...formData, ...data, type: formData.type, avatar: formData.avatar };

    for (const key in newData) {
      if (!newData[key] && newData[key] !== null) {
        delete newData[key];
      }
    }
    try {
      const script = await updateScript(newData);
      setFormData({ ...newData, ...script });
    } catch (error) {
      console.log(error);
    }
    setScriptGenerated(true);
    setLoading(false);
  };

  return (
    <>
      <Backdrop
        open={show}
        sx={{
          color: "#fff",
          backgroundColor: "background.default",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        onClick={() => {
          setShow(false);
        }}
      ></Backdrop>

      <Box
        hidden={!show}
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <HeaderAppBar
          closeAction={() => {
            setShow(false);
          }}
        />
        <Container component="main" maxWidth="sm">
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, borderRadius: 2 }}
          >
            <Typography component="h1" variant="h4" align="center">
              Edit your web script
            </Typography>

            <React.Fragment>
              <Tabs
                variant="fullWidth"
                value={activeTab}
                onChange={handleTabChange}
                aria-label="hook tab"
              >
                <Tab label="Application details" {...a11yProps(0)} />
                <Tab label="Login details" {...a11yProps(1)} />
              </Tabs>

              <FormContainer
                defaultValues={data}
                p={2}
                onSuccess={handleFormSubmit}
              >
                <Box sx={{ mt: 2 }}>
                  <TabContent
                    tab={activeTab}
                    scriptGenerated={scriptGenerated}
                    setScriptGenerated={setScriptGenerated}
                    tabType={handleTabType}
                    data={formData}
                    avatarUpload={avatarUpload}
                    defaultAvatar={data.avatar}
                  />
                </Box>

                <Divider sx={{ my: 2 }} />

                <GenerateScriptActionInfo
                  scriptGenerated={scriptGenerated}
                  setScriptGenerated={setScriptGenerated}
                  saveForm={handleSubmit(handleFormSubmit)}
                  data={formData}
                />
              </FormContainer>
            </React.Fragment>
          </Paper>
        </Container>
      </Box>
    </>
  );
}
