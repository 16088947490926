import {
  getFirestore,
  getDoc,
  collection,
  addDoc,
  doc,
  updateDoc,
  onSnapshot,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { app } from "./auth";
import { partnerKey } from "./utils";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

//API for scripts

const db = getFirestore(app);
// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);

//Get all the scripts.
const scripts = async () => {
  const collRef = collection(
    db,
    "socket_partner",
    await partnerKey(),
    "scripts"
  );
  const docsSnap = await getDocs(collRef);
  if (docsSnap.docs.length > 0) {
    return docsSnap.docs.map((doc) => doc.data());
  }
  return null;
};

//Add/Update a script
const updateScript = async (script) => {
  let scriptToUpdate = {};
  const key = await partnerKey();
  const partnerDocRef = doc(db, "socket_partner", key);
  const colRef = collection(partnerDocRef, "scripts");
  let uploadAvatar = false;

  let existingDocRef;
  if (script.scriptId) {
    existingDocRef = doc(colRef, script.scriptId);
    const existingDocSnap = await getDoc(existingDocRef);
    if (existingDocSnap.exists()) {
      scriptToUpdate = existingDocSnap.data();
    }

    if (script.avatar && script.avatar !== scriptToUpdate.avatar) {
      uploadAvatar = true;
    }

    for (const key in script) {
      if (script[key]  || script[key] === null) {
        scriptToUpdate[key] = script[key];
      }
    }
  } else {
    scriptToUpdate = script;
    scriptToUpdate.logins = 0;
    scriptToUpdate.created = new Date();
    if (script.avatar) {
      uploadAvatar = true;
    }
  }

  scriptToUpdate.scriptSrc = process.env.REACT_APP_SCRIPT_SRC;
  scriptToUpdate.scriptToken = Date.now();

  if (!scriptToUpdate.type) {
    scriptToUpdate.type = "basic";
  }
  let updateRes;
  if (existingDocRef) {
    await updateDoc(existingDocRef, scriptToUpdate);
    script.scriptData = script?.scriptId;
    updateRes = existingDocRef;
  } else {
    updateRes = await addDoc(colRef, scriptToUpdate);
    scriptToUpdate.scriptId = updateRes.id;
    await updateDoc(updateRes, {
      scriptId: updateRes.id,
    });
  }

  if (uploadAvatar) {
    const storageRef = ref(storage, `dashboard_avatars/${scriptToUpdate.scriptId}`);
    const response = await fetch(scriptToUpdate.avatar);
    const blob = await response.blob();
    await uploadBytes(storageRef, blob);
    const avatar = await getDownloadURL(storageRef);

    await updateDoc(updateRes, {
      avatar: avatar,
    });
  }

  return (await getDoc(updateRes)).data();
};

const deleteScript = async (script) => {
  const key = await partnerKey();
  const partnerDocRef = doc(db, "socket_partner", key);
  const colRef = collection(partnerDocRef, "scripts");
  const docRef = doc(colRef, script.scriptId);
  await deleteDoc(docRef);
};

//Listen for changes to the script details
//Pass in a useState setter function to update the state
const scriptListener = async (setStateFunc) => {
  const docRef = doc(db, "socket_partner", await partnerKey());
  const colRef = collection(docRef, "scripts");

  const unsub = onSnapshot(colRef, (snap) => {
    const rows = [];
    for (const doc of snap.docs) {
      rows.push(doc.data());
    }
    setStateFunc(rows);
  });
  return unsub;
};

export { scripts, updateScript, scriptListener, deleteScript };
