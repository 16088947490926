import * as React from "react";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../api/auth";
import { partner } from "../api/partner";
import HeaderAppBar from "../Components/Common/HeaderAppBar";

//Welcome page for newly registered users
export default function Welcome() {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [comany, setCompany] = React.useState("Forghetti");

  const fetchPartner = React.useCallback(async () => {
    try {
      const p = await partner();
      setCompany(p.name);
    } catch (err) {
      console.error(err);
    }
  }, []);

  React.useEffect(() => {
    if (error) {
      console.error(error);
      alert(error.message);
      return;
    }
    if (loading) return;
    if (!user) return navigate("/login");
    if (user) {
      fetchPartner();
    }
  }, [error, fetchPartner, loading, navigate, user]);

  return (
    <>
      <HeaderAppBar
        closeAction={() => {
          navigate("/");
        }}
      />

      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 },}}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h4" align="center">
                Welcome
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h2"
                sx={{ color: "primary.main" }}
                gutterBottom
                textAlign={"center"}
              >
                {comany}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
              Welcome to the forghetti partner dashboard. Here you will be able to create web scripts for your sites and applications. Peace of mind knowing that all your users are logging with secure passwords easily.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
              Let's begin by creating your first authentication script.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/new");
                }}
              >
                Begin
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
