import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { CopyBlock, dracula } from "react-code-blocks";


export default function GenerateScriptActionInfo({scriptGenerated, setScriptGenerated, saveForm, data}) {

  return (
    <React.Fragment>
      <Grid item xs={12} hidden={!scriptGenerated}>
          <Typography variant="h6" gutterBottom>
         
        Your script
      </Typography>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12} hidden={!scriptGenerated}>
          <Typography variant="subtitle2">
            Paste the script tag into the header of your website and you are ready to start
            using forghetti hooks.
          </Typography>
        </Grid>
        <Grid item xs={12} hidden={scriptGenerated}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
           // onClick={saveForm}
          >
            Regenerate script
          </Button>
        </Grid>
        <Grid item xs={12} hidden={!scriptGenerated}>
          <CopyBlock
            text={
              `<script src="${data?.scriptSrc}" data-forghetti="${data?.scriptId}" data-token="${data?.scriptToken}"></script>`
            }
            language="html"
            showLineNumbers={false}
            theme={dracula}
          ></CopyBlock>
        </Grid>
      
        <Grid item xs={12} hidden={!scriptGenerated}>
          <Typography variant="subtitle2">
            Paste this second element into your website body and you are ready to start.
          </Typography>
        </Grid>
        <Grid item xs={12} hidden={!scriptGenerated}>
          <CopyBlock
            text={
              `<div data-start="launch-forghetti" id="forghetti-${data?.scriptId}"></div>`
            }
            language="html"
            showLineNumbers={false}
            theme={dracula}
          ></CopyBlock>
          </Grid>
          <Grid item xs={12} hidden={!scriptGenerated}>
            <Typography variant="subtitle2">
            A button will be added to your website when the script loads.
            </Typography> 
            <Typography variant="subtitle2">
            You can change the width and height of the button by passing data-button-height and data-button-width as attributes to script tag above.
            </Typography>
        

            <Typography variant="subtitle2">
            You can use your own button by passing data-custom-button="true" as an attribute to the script tag above and style it how you want. 
            </Typography>
            <Typography variant="subtitle2">
            By default the script will initialise itself automatically. If you want to control this behaviour yourself you can pass `data-custom-initialise="true"` as an attribute to the script tag above.
            </Typography>
            <Typography variant="subtitle2">
            Just be sure to include the attribute id="forghetti-${data?.scriptId}" on the element you want to use as the button.
          </Typography>
        
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
