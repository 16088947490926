import {  Button, Grid, Link,  Typography } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export default function NewScriptCompletionInfo() {

  const navigate=  useNavigate()
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom textAlign={'center'}>
            Thank you for creating a web script
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="subtitle1">
            You will be able to create more scripts and access this script from
            your dashboard at any time
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            If you should need any further help please contact us at: 
       
          </Typography>
          <Link href="mailto:support@forghetti.com">
              support@forghetti.com 
            </Link>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant="contained" color="primary" onClick={()=>{
            navigate("/")
          }}>
            Go to dashboard
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
