import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, logout, passwordLessLogin } from "../api/auth";

import { Alert, Paper } from "@mui/material";
import ForghettiLogo from "../Components/Common/ForghettiLogo";
import LoadingScreenBackdrop from "../Components/Common/LoadingScreenBackdrop";

function Invite() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const invite = async (data) => {
    try {
      const done = await passwordLessLogin(
        data.email,
        data.password,
        data.passwordconfirm
      );
      if (done.success) {
        navigate("/login");
      } else {
        setShowError(true);
        setError(done.message);
      }
    } catch (err) {
      setShowError(true);
      setError("Your credentials are invalid");
    }
  };
  useEffect(() => {
    if (loading) return;
    if (user) {
      logout();
    }
  }, [user, loading, navigate]);

  const clearError = () => {
    setShowError(false);
    setError("");
  };

  return (
    <Container component="main" maxWidth="xs">
      <LoadingScreenBackdrop open={loading} />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper sx={{ padding: 4 }}>
          <ForghettiLogo />

          <Typography
            align="center"
            mt={3}
            component="h1"
            variant="h5"
            sx={{
              justifyContent: "center",
              textAlign: "center",
              color: "text.secondary",
            }}
          >
            Complete user registration
          </Typography>

          <FormContainer
            defaultValues={{ companyName: "", email: "", password: "" }}
            onSuccess={invite}
          >
            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12}>
                <TextFieldElement
                  required
                  fullWidth
                  id="email1"
                  type="email"
                  label="Email Address"
                  name="email"
                  autoComplete="new-password"
                  onChange={clearError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldElement
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={clearError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldElement
                  required
                  fullWidth
                  name="passwordconfirm"
                  label="Confirm password"
                  type="password"
                  id="passwordconfirm"
                  autoComplete="new-password"
                  onChange={clearError}
                />
              </Grid>

              <Grid item xs={12}>
                <Alert sx={{ display: !showError && "none" }} severity="error">
                  {error}
                </Alert>
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor: "primary" }}
                >
                  COMPLETE LOGIN
                </Button>
              </Grid>
            </Grid>
          </FormContainer>
        </Paper>
      </Grid>
    </Container>
  );
}
export default Invite;
