import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import { FormContainer, TextFieldElement } from "react-hook-form-mui";

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, registerWithEmailAndPassword } from "../api/auth";

import { Alert, Paper } from "@mui/material";
import ForghettiLogo from "../Components/Common/ForghettiLogo";
import LoadingScreenBackdrop from "../Components/Common/LoadingScreenBackdrop";

function Register() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const register = async (data) => {
    if (data.password !== data.passwordconfirm) {
      setError("Passwords do not match");
      setShowError(true);
      return;
    }
    try {
      await registerWithEmailAndPassword(
        data.companyName,
        data.email,
        data.password
      );

      navigate("/welcome");
    } catch (err) {
      setShowError(true);
      setError(err.message);
    }
  };

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading, navigate]);

  const clearError = () => {
    setShowError(false);
    setError("");
  };

  return (
    <Container component="main" maxWidth="xs">
      <LoadingScreenBackdrop />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper sx={{ padding: 4 }}>
          <ForghettiLogo />

          <Typography
            align="center"
            mt={3}
            component="h1"
            variant="h5"
            sx={{
              justifyContent: "center",
              textAlign: "center",
              color: "text.secondary",
            }}
          >
            Partner Registration
          </Typography>

          <FormContainer
            defaultValues={{ companyName: "", email: "", password: "" }}
            onSuccess={register}
            
          >
              <Grid container spacing={2} sx={{ mt: 3}}>
                <Grid item xs={12} sm={12}>
                  <TextFieldElement
                    autoComplete="given-name"
                    name={"companyName"}
                    required
                    fullWidth
                    id="companyName"
                    label="Company name"
                    autoFocus
                    onChange={clearError}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldElement
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    type="email"
                    name="email"
                    autoComplete="email"
                    onChange={clearError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldElement
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    onChange={clearError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldElement
                    required
                    fullWidth
                    name="passwordconfirm"
                    label="Confirm password"
                    type="password"
                    id="passwordconfirm"
                    autoComplete="new-password"
                    onChange={clearError}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Alert sx={{ display: !showError && "none" }} severity="error">
                  {error}
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor: "primary" }}
                >
                  REGISTER
                </Button>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="body2"
                    sx={{ color: "primary.dark" }}
                  >
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
          </FormContainer>
        </Paper>
      </Grid>
    </Container>
  );
}
export default Register;
