import {
  getFirestore,
  getDoc,
  doc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";

import { app,  } from "./auth";
import { partnerKey } from "./utils";

//Partner details API , doesn't include the scripts!
const db = getFirestore(app);

//Get the partner details
const partner = async () => {
  const docRef = doc(db, "socket_partner", await partnerKey());
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log("No such document!");
  }
  return null;
};

//Update the partner details
const updatePartner = async (partner) => {
  const docRef = doc(db, "socket_partner", await partnerKey());
  const update = {
    name: partner.name,
    updated: Date.now(),
    contactNumber: partner.contactNumber,
    contactName: partner.contactName,
    contactEmail: partner.contactEmail,
  };
  await updateDoc(docRef, update);
};

//Add a sub users
const updateUsers = async (user, existingUserId) => {
  const docRef = doc(db, "socket_partner", await partnerKey());
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const users = docSnap.data().users;

    if (existingUserId) {
      const eU = users.find((u) => u.uid === existingUserId);

      for (const key in user) {
        if (user[key]) {
          eU[key] = user[key];
        }
      }
      eU.updated = new Date();
    } else {
      users.push({
        role: user.role,
        name: user.name,
        email: user.email,
        created: new Date(),
      });
    }
    await updateDoc(docRef, {users});
  } else {
    console.log("No such document!");
    return;
  }
};

const deleteUser = async (userId) => {
  const docRef = doc(db, "socket_partner", await partnerKey());
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    const users = data.users.filter((u) => u.uid !== userId);
  
    await updateDoc(docRef, {users});
  } else {
    console.log("No such document!");
    return;
  }
};

//Listen for changes to the partner details
//Pass in a useState setter function to update the state
const partnerListener = async (setStateFunc) => {
  const unsub = onSnapshot(doc(db, "socket_partner", await partnerKey()), (doc) => {
    setStateFunc(doc.data());
  });
  return unsub;
};

export {  partner, updatePartner, updateUsers, partnerListener, deleteUser };
