import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextFieldElement } from "react-hook-form-mui";
import styled from "@emotion/styled";
import { Avatar, Button } from "@mui/material";
import { Delete, Upload } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useFormAction } from "react-router-dom";

import { useForm } from "react-hook-form-mui";

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
  ${({ $withBorder }) =>
    $withBorder &&
    `border: 1px solid #000;
     box-shadow: 0 0 1px 0 #000 inset, 0 0 1px 0 #000;`}
`;

//Set the application details
export default function ApplicationDetails({
  setScriptGenerated,
  avatarUpload,
  defaultAvatar,
}) {
  const inputFileRef = React.createRef(null);

  const [image, _setImage] = React.useState(defaultAvatar);

  const cleanup = () => {
    URL.revokeObjectURL(image);
    avatarUpload(null);
    inputFileRef.current.value = null;
  };
  const setImage = (newImage) => {
    if (image) {
      cleanup();
    }
    _setImage(newImage);
  };
  const handleImageUploadClick = (event) => {
    if (image) {
      event.preventDefault();
      setImage(null);
    }
  };

  const handleImageChange = (event) => {
    const newImage = event.target?.files?.[0];

    if (newImage) {
      const url = URL.createObjectURL(newImage);
      setImage(url);
      avatarUpload(url);
    }
  };

  const onChange = () => {
    setScriptGenerated(false);
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Application details
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box onClick={handleImageUploadClick}>
            <BigAvatar
              $withBorder
              alt="Avatar"
              src={image || "/static/img/avatars/default-profile.svg"}
              imgProps={{
                style: {
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "cover",
                },
              }}
            />
          </Box>

          <input
            ref={inputFileRef}
            accept="image/*"
            hidden
            id="avatar-image-upload"
            type="file"
            onChange={handleImageChange}
          />
        </Grid>
        <Grid item xs={12}>
          <label htmlFor="avatar-image-upload">
            <Button
              variant="contained"
              color="primary"
              component="span"
              mb={2}
              onClick={handleImageUploadClick}
            >
              {image ? <Delete mr={2} /> : <Upload mr={2} />}
              {image ? "Delete" : "Upload"}
            </Button>
          </label>
        </Grid>

        <Grid item xs={12}>
          <TextFieldElement
            required
            id="websiteName"
            name="websiteName"
            label="Website name"
            fullWidth
            autoComplete="website0name"
            variant="standard"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldElement
            required
            id="baseURL"
            name="baseURL"
            label="Your company web address"
            fullWidth
            type="url"
            autoComplete="family-name"
            variant="standard"
            onChange={onChange}
            rules={{}}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldElement
            id="loginURL"
            name="loginURL"
            label="The URL to your login page"
            type="url"
            fullWidth
            autoComplete="login url"
            variant="standard"
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
