import { Box, Button, Grid, Link, TextField, Typography } from "@mui/material";
import * as React from "react";
import { CopyBlock, dracula } from "react-code-blocks";

import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { scrape } from "../api/scrape";

export default function BasicDOMInfo() {
    const [loginUrl, setLoginUrl] = React.useState("");
  return (
    <Box >
      <Typography variant="h5" gutterBottom>
        Basic DOM access
      </Typography>

      <Typography variant="subtitle2">
        A DOM selector is a JavaScript method used to select and retrieve
        specific elements from a web page's Document Object Model (DOM). The DOM
        is a hierarchical structure that represents the web page's structure,
        where each element is represented as a node in the tree.
      </Typography>

      <Typography marginTop={2} variant="h6" sx={{ color: "secondary.main" }}>
        Finding the right DOM selector for Forghetti
      </Typography>

      <Typography variant="subtitle2">
        Right click on the element you want to use for the password, username
        and or email and select "Inspect". This will open the developer tools.
        You can then click on the element you want to use and the DOM selector
        will be displayed in the developer tools.
      </Typography>

      <Typography variant="subtitle2">
        Forghetti will use each element it finds in the selector
        you provide using document.querySelectorAll() method
      </Typography>

      <Typography marginTop={2} variant="h6" sx={{ color: "secondary.main" }}>
        ID selector
      </Typography>

      <Typography variant="subtitle2">
        If your element has an ID attribute, you can use the ID selector to
        select the element. The ID selector is written with a hash character (#)
        followed by the value of the ID attribute of the element.
      </Typography>

      <CopyBlock
        text={"#password"}
        language="javascript"
        showLineNumbers={false}
        theme={dracula}
      ></CopyBlock>

      <Typography marginTop={2} variant="h6" sx={{ color: "secondary.main" }}>
        Class selector
      </Typography>

      <Typography variant="subtitle2">
        If your element has a unqiue class attribute, you can use the class
        selector to select the element. The class selector is written with a
        period character (.) followed by the value of the class attribute of the
        element.
      </Typography>
      <CopyBlock
        text={"input.password"}
        language="javascript"
        showLineNumbers={false}
        theme={dracula}
      ></CopyBlock>

      <Typography marginTop={2} variant="h6" sx={{ color: "secondary.main" }}>
        Attribute selector
      </Typography>

      <Typography variant="subtitle2">
        If your element has a unqiue attribute, you can use the attribute
        selector to select the element. The attribute selector is written with a
        square bracket character ([attribute]) followed by the value of the
        attribute of the element.
      </Typography>
      <CopyBlock
        text={'input[type="password"]'}
        language="javascript"
        showLineNumbers={false}
        theme={dracula}
      ></CopyBlock>

      <Typography marginTop={2} variant="h6" sx={{ color: "secondary.main" }}>
        Get Forghetti to fill in your form
      </Typography>

      <Typography variant="subtitle2">
        Forhgetti will try access and understand your login form. Click the
        button below to execute this.
      </Typography>

      <Typography variant="subtitle2">
        Please note: This may fail due to the login form being protected
      </Typography>

      <Box m={2}>
        <FormContainer
          defaultValues={{ companyName: "", email: "", password: "" }}
          onSuccess={(data) => {
            scrape(data)
          }}
        >
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="scrape"
              type="scrape"
              label="Login URL"
              name="scrape"
              autoComplete="new-password"
              onChange={(event)=>{
                setLoginUrl(event.target.value)
              }}
              
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <Button onClick={()=>{
                scrape(loginUrl)
            }} fullWidth m={2}>
              Get Forghetti to fill in your form
            </Button>
          </Grid>
        </FormContainer>
      </Box>
      <Typography marginTop={2} variant="h6" sx={{ color: "secondary.main" }}>
        More info on DOM selectors
      </Typography>

      <Typography variant="subtitle2">
        For more information on DOM selectors, please visit the following link:{" "}
        <Link href="https://www.w3schools.com/cssref/css_selectors.asp" target="_blank">
          https://www.w3schools.com/cssref/css_selectors.asp
        </Link>
      </Typography>
    </Box>
  );
}
