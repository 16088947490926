import React, { useCallback, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, canEdit } from "../api/auth";

import { partner } from "../api/partner";

import HeaderAppBar from "../Components/Common/HeaderAppBar";
import ScriptsTable from "../Components/ScriptsTable";
import LoadingScreenBackdrop from "../Components/Common/LoadingScreenBackdrop";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [editable, setEditable] = useState(false);
  const [editableDone, setEditableDone] = useState(false);

  const navigate = useNavigate();

  //A little callback to get the dashboard usernam
  const fetchUserName = useCallback(async () => {
    try {
      const dbUser = await partner();
      setName(dbUser.name);
      console.log("name", dbUser);
    } catch (err) {
      console.error(err);
    }
  }, []);

  //PROTECTED ROUTE
  //Call fetchusername when user changes, make sure dependency array is correct
  useEffect(() => {
    if (error) {
      console.log("error", error);
      alert("Error: " + error.message);
    }
    if (loading) return;
    if (!user) return navigate("/login");
    if (user) {
      fetchUserName();
    }
  }, [error, fetchUserName, loading, navigate, user]);

  useEffect(() => {
    canEdit("Dashboard").then((can) => {
      console.log("can", can);
      setEditable(can);
      setEditableDone(true);
    });
  }, []);

  if (loading) {
    return <LoadingScreenBackdrop open={loading} />;
  }
  return (
    <>
      <HeaderAppBar logoutAction={() => {}} settingAction={() => {}} />
      <Container>
        <Grid container spacing={5} mt={1}>
          <Grid item xs={12} justifyContent="center">
            <Paper>
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  alignItems: "center",
                  p: 2,
                  pb: 0,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ justifyContent: "space-between", flexGrow: 1 }}
                >
                  Welcome
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  alignItems: "center",
                  p: 2,
                  color: "primary.main",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ justifyContent: "space-between", flexGrow: 1 }}
                >
                  {name}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} justifyContent="center">
            <ScriptsTable editable={editable} editableDone={editableDone} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
export default Dashboard;
