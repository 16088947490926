import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../api/auth";

import { Container } from "@mui/system";
import HeaderAppBar from "../Components/Common/HeaderAppBar";
import LoadingScreenBackdrop from "../Components/Common/LoadingScreenBackdrop";
import PartnerDetails from "../Components/PartnerDetails";
import UsersTable from "../Components/UsersTable";
import { Grid } from "@mui/material";

export default function Settings() {
  //The user, loading and error state from firebase
  const [user, loading, error] = useAuthState(auth);


  const navigate = useNavigate();


  React.useEffect(() => {
    if (error) {
      navigate("/");
    }
    if (loading) return;
    if (!user) navigate("/login");
  }, [user, error, navigate, loading]);

  
  //Display this whilst waiting for a user.
  if (loading) {
    return <LoadingScreenBackdrop open={true} />;
  }


  return (
    <>
      <HeaderAppBar
        closeAction={() => {
          console.log("close");
          navigate("/");
        }}
      />
      <Container>
        <Grid container spacing={5} mt={1}>
         
          <Grid
            item
            xs={12}
          
            justifyContent="center"
          >
            <PartnerDetails />
          </Grid>
          <Grid item xs={12}  justifyContent="center">
            <UsersTable />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
