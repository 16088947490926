import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Dashboard from "./Pages/Dashboard";

import CssBaseline from "@mui/material/CssBaseline";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormProvider } from "react-hook-form";
import NewScript from "./Pages/NewScript";
import Welcome from "./Pages/Welcome";
import Settings from "./Pages/Settings";
import React from "react";
import { ToastProvider } from "./Providers/ToastProvider";
import Invite from "./Pages/Invite";
import ResetPassword from "./Pages/ResetPassword";
import { theme } from "./api/utils";
import FieldSelector from "./Pages/FieldSelector";

//The world starts here
function App() {

  

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <FormProvider>
          <CssBaseline />
          <div className="app">
            <Router>
              <Routes>
                {
                  //Add your routes here!!
                  //NOTE: REMEMBER TO PROTECT NEW ROUTES if neccesary in the below routes
                  //E.G
                  // React.useEffect(()=>{
                  //   if (loading) return;
                  //   if (!user) return navigate("/login");
                  // }, [fetchUserName, loading, navigate, user]);
                }
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/reset" element={<ResetPassword />} />
                <Route exact path="/" element={<Dashboard />} />
                <Route exact path="/welcome" element={<Welcome />} />
                <Route exact path="/new" element={<NewScript />} />
                <Route exact path="/settings" element={<Settings />} />
                <Route exact path="/invite" element={<Invite />} />

                <Route exact path="/fs" element={<FieldSelector />} />
              </Routes>
            </Router>
          </div>
        </FormProvider>
      </ToastProvider>
    </ThemeProvider>
  );
}
export default App;
