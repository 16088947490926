import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, canEdit, role as authRole} from "../api/auth";
import {
  Box,
  Button,
  IconButton,
  Paper,
  TableContainer,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import {
  deleteUser,
  partnerListener,
} from "../api/partner";
import DeleteUserDialog from "../Components/DeleteUserDialog";
import LoadingScreenBackdrop from "../Components/Common/LoadingScreenBackdrop";
import UpdateUser from "../Pages/UpdateUser";


export default function UsersTable() {
  //Reference to the unsubscribe function on the snapshot
  const partnerListenerUnsub = useRef(null);
  //The user, loading and error state from firebase
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();


  const [showEditUser, setShowEditUser] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);

  const [userRows, setUserRows] = useState([]);
  const [currentUserRow, setCurrentUserRow] = useState(null);
  const [editable, setEditable] = useState(null);
  const [editableDone, setEditableDone] = useState(false);

  const fetchPartner = useCallback(async () => {
    try {
      partnerListenerUnsub.current = partnerListener((partner) => {
        //Callback when the snapshot changes

        //make sure there is at least one owner
        if (partner.users.filter((user) => user.role === "owner").length <= 1) {
          for (const user of partner.users) {
            if (user.role === "owner") {
              user.stopDelete = true;
            }
          }
        }
        setUserRows(partner.users);
      });
    } catch (err) {
      console.error(err);
    }
  }, []);

  //PROTECT ROUTE
  useEffect(() => {
    //we're logged in, fetch the partner
    fetchPartner();

    //Make sure to unsubscribe from the listener
    return () => {
      if (
        partnerListenerUnsub.current &&
        typeof partnerListenerUnsub.current === "function"
      ) {
        partnerListenerUnsub.current();
      }
    };
  }, [user, loading, navigate, fetchPartner, error]);

  useEffect( ()=>{
    canEdit('UsersTable').then((can)=>{
        setEditable(can)
        setEditableDone(true)
        }
    )
  },[])

  const userTableColumns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "role", //access nested data with dot notation
        header: "Role",
      },
      {
        accessorKey: "created", //normal accessorKey
        header: "Created",
        Cell: ({ cell }) => {
          return cell.getValue()
            ? cell.getValue().toDate().toLocaleString()
            : ""; //render Date as a string
        },
      },
      {
        accessorKey: "updated", //normal accessorKey
        header: "Updated",
        Cell: ({ cell }) => {
          return cell.getValue()
            ? cell.getValue().toDate().toLocaleString()
            : ""; //render Date as a string
        },
      },
    ],
    []
  );

  //Dialog for deleting a user
  const handleDeleteRow = (row) => {
    setShowDeleteDialog(true);
    setCurrentUserRow(row.original);
  };

  //Actually performa the delete
  const handleDelete = () => {
    setShowDeleteDialog(false);
    deleteUser(currentUserRow.uid);
    setCurrentUserRow(null);
  };

  //Component for editing a user
  const handleEditRow = (row) => {
    setShowEditUser(true);
    setCurrentUserRow(row.original);
  };

  //Display this whilst waiting for a user.
  if (loading) {
    return <LoadingScreenBackdrop open={true} />;
  }



  return (
    <>
      <TableContainer
        component={Paper}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
          }}
        >
          <Typography noWrap variant="h6" sx={{ py: 2,pl: 2  }}>
            Users
          </Typography>
          {editable && <Button
            variant="contained"
            sx={{ py: 1, px: 2, mr: 2, backgroundColor: "primary" }}
            onClick={() => setShowAddUser(true)}
            
          >
            Add user
          </Button> }
        </Box>

        {editableDone && <MaterialReactTable
          columns={userTableColumns}
          data={userRows}
          enableBottomToolbar={false}
          enableFilterMatchHighlighting={true}
          enableGlobalFilter={true}
          enableRowActions={editable}
          enableClickToCopy={true}
          enableResizing={true}
          positionActionsColumn="last"
          renderRowActions={({ row, table }) => {
         
            return (
              <Box sx={{ display: "flex" }}>
                <Tooltip arrow placement="left" title="Edit">
                  <IconButton onClick={() => handleEditRow(row)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                {!row.original.stopDelete && (
                  <Tooltip arrow placement="right" title="Delete">
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteRow(row)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            );
          }}
        /> }
      </TableContainer>

      {showAddUser && editable && <UpdateUser show={showAddUser} setShow={setShowAddUser} />}
      {showEditUser && editable &&  (
        <UpdateUser
          show={showEditUser}
          setShow={setShowEditUser}
          data={currentUserRow}
        />
      )}
      {showDeleteDialog && editable && (
        <DeleteUserDialog
          open={showDeleteDialog}
          setOpen={setShowDeleteDialog}
          deleteAction={handleDelete}
        ></DeleteUserDialog>
      )}
    </>
  );
}
