import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { Button, Paper } from "@mui/material";
import { updatePartner as dbUpdatePartner } from "../api/partner";
import { useToast } from "../Providers/ToastProvider";
import LoadingScreenBackdrop from "./Common/LoadingScreenBackdrop";
import { partner as dbPartner } from "../api/partner";

import {  canEdit } from "../api/auth";

export default function PartnerDetails() {
  const { showToast } = useToast();
  const [partner, setPartner] = React.useState();
  const [partnerLoading, setPartnerLoading] = React.useState(true);
  const [updating, setUpdating] = React.useState(false);
  const [editable, setEditable] = React.useState(null);

  const fetchPartner = React.useCallback(async () => {
    setPartnerLoading(true);
    try {
      const p = await dbPartner();
      setPartner(p);
    } catch (err) {
      console.error(err);
    }
    setPartnerLoading(false);
  }, []);

  //PROTECT ROUTE
  React.useEffect(() => {
    //we're logged in, fetch the partner
    fetchPartner();
  }, [fetchPartner]);


  React.useEffect( ()=>{
    canEdit('PartnerDetails').then((can)=>{
        setEditable(can)
        }
    )
  },[])

  //Submit the form
  const handleSubmit = async (data) => {
    setUpdating(true);
    try {
      console.log("updateCompany", data);
      setPartner(data);
      await dbUpdatePartner(data);
      showToast("Details updated!");
    } catch (err) {
      console.error(err);
      showToast("Error updating details", { severity: "error" });
    }
    setUpdating(false);
  };

  if (updating || partnerLoading) {
    return <LoadingScreenBackdrop open={updating || partnerLoading} />;
  }
  return (
    <FormContainer
      onSuccess={handleSubmit}
      defaultValues={{
        name: partner?.name ? partner?.name : "",
        contactName: partner?.contactName ? partner?.contactName : "",
        contactNumber: partner?.contactNumber ? partner?.contactNumber : "",
        contactEmail: partner?.contactEmail ? partner?.contactEmail : "",
      }}
    >
      <Paper>
        <Typography noWrap variant="h6" sx={{ p:2 }}>
          Company settings
        </Typography>

        <Grid container spacing={2} sx={{p: 2}}>
          <Grid item xs={12} >
            <TextFieldElement
              required
              fullWidth
              variant="standard"
              id="name"
              label="Company Name"
              name="name"
              autoComplete="new-password"
              disabled={!editable}
            />
          </Grid>
          <Grid item xs={12} >
            <TextFieldElement
              fullWidth
              variant="standard"
              id="contactName"
              label="Contact name"
              name="contactName"
              autoComplete="new-password"
              disabled={!editable}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldElement
              fullWidth
              variant="standard"
              id="contactNumber"
              label="Contact number"
              type="number"
              name="contactNumber"
              autoComplete="new-password"
              disabled={!editable}
            />
          </Grid>
          <Grid item xs={12} >
            <TextFieldElement
              fullWidth
              variant="standard"
              id="contactEmail"
              type="email"
              label="Contact email address"
              name="contactEmail"
              autoComplete="new-password"
              disabled={!editable}
            />
          </Grid>

          {  editable && <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              sx={{  backgroundColor: "primary" }}
            >
              Update
            </Button>
          </Grid> }
        </Grid>
      </Paper>
    </FormContainer>
  );
}
