import { Alert, Paper, TextField } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React, { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { FormContainer, TextFieldElement } from "react-hook-form-mui"
import { useNavigate } from "react-router-dom"

import ForghettiLogo from "../Components/Common/ForghettiLogo"
import LoadingScreenBackdrop from "../Components/Common/LoadingScreenBackdrop"
import { auth, logout, passwordLessLogin } from "../api/auth"

function FieldSelector({username, email, password}) {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  const [showError, setShowError] = useState(false)
  const [error, setError] = useState("")
//   const invite = async (data) => {
//     try {
//       const done = await passwordLessLogin(
//         data.email,
//         data.password,
//         data.passwordconfirm
//       )
//       if (done.success) {
//         navigate("/login")
//       } else {
//         setShowError(true)
//         setError(done.message)
//       }
//     } catch (err) {
//       setShowError(true)
//       setError("Your credentials are invalid")
//     }
//   }
//   useEffect(() => {
//     if (loading) return
//     if (user) {
//       logout()
//     }
//   }, [user, loading, navigate])

  const clearError = () => {
    setShowError(false)
    setError("")
  }

  return (
    <Container component="main" sx={{minWidth: '400px'}}>
      <LoadingScreenBackdrop open={loading} />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}>
       
          <ForghettiLogo />

          <Typography
            align="center"
            mt={3}
            component="h1"
            variant="h5"
            sx={{
              justifyContent: "center",
              textAlign: "center",
              color: "text.secondary"
            }}>
            Field selector
          </Typography>

          <FormContainer
            defaultValues={{ companyName: "", email: "", password: "" }}
            >
            <Grid container spacing={2} sx={{ mt: 3 }}>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="username"
                  type="username"
                  label="Username"
                  name="username"
                  autoComplete="new-password"
                  onChange={clearError}
                  value={username}
                  
                />
              </Grid>

           
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="email"
                  label="Email"
                  type="email"
                  id="email"
                  autoComplete="new-password"
                  onChange={clearError}
                  value={email}
                />
              </Grid>

          
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  label="Password"
                  id="password"
                  autoComplete="new-password"
                  onChange={clearError}
                  value={password}
                />
              </Grid>

              <Grid item xs={12}>
                <Alert sx={{ display: !showError && "none" }} severity="error">
                  {error}
                </Alert>
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={()=>{
                    console.log("username: ", username)
                    console.log("email: ", email)
                    console.log("password: ", password)
                  }}
                  sx={{ mt: 3, mb: 2, backgroundColor: "primary" }}>
                  Test injection
                </Button>
              </Grid>
            </Grid>
          </FormContainer>
    
      </Grid>
    </Container>
  )
}
export default FieldSelector
