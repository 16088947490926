import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import {
  Alert,
  Backdrop,
  Grid,
} from "@mui/material";
import { updateUsers } from "../api/partner";
import { useToast } from "../Providers/ToastProvider";
import HeaderAppBar from "../Components/Common/HeaderAppBar";

export default function UpdateUser({ show, setShow, data }) {
  const [showError, setShowError] = React.useState(false);
  const [error, setError] = React.useState("");


  const { showToast } = useToast();

  const clearError = () => {
    setShowError(false);
    setError("");
  };

  const handleSubmit = async (form) => {
    try {
      await updateUsers(form, data?.uid);

      showToast(data?.uid ? "Details updated!" : "User added!");
    } catch (err) {
      console.error(err);
      showToast("Error updating details", { severity: "error" });
    }
    setShow(false);
  };

 

  return (
    <>
      <Backdrop
        open={show}
        sx={{
          color: "#fff",
          backgroundColor: "background.default",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        onClick={() => {
          setShow(false);
        }}
      ></Backdrop>

      <Box
        hidden={!show}
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <HeaderAppBar
          closeAction={() => {
            setShow(false);
          }}
        />
        <Container component="main" maxWidth="sm" >
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, }}
          >
            <Typography component="h1" variant="h4" align="center">
              {data ? "Edit user" : "Add a new user"}
            </Typography>

            <React.Fragment>
              <FormContainer
                defaultValues={{
                  name: data?.name,
                  email: data?.email,
                  role: data?.role,
                }}
                p={2}
              
                onSuccess={handleSubmit}
              >
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12} sm={12}>
                    <TextFieldElement
                      autoComplete="given-name"
                      name={"name"}
                      required
                      fullWidth
                      id="name"
                      label="Name"
                      autoFocus
                      onChange={clearError}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldElement
                      required
                      fullWidth
                      disabled={data ? true : false}
                      id="email"
                      label="Email Address"
                      type="email"
                      name="email"
                      autoComplete="email"
                      onChange={clearError}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <SelectElement
                      required
                      labelId="role-label"
                      id="role"
                      fullWidth
                      name="role"
                      value={"admin"}
                      label="Role"
                      onChange={() => {}}
                      options={[
                        { id: "owner", label: "Owner" },
                        { id: "admin", label: "Admin" },
                        { id: "user", label: "User" },
                      ]}
                    ></SelectElement>
                  </Grid>

                  <Grid item xs={12}>
                    <Alert
                      sx={{ display: !showError && "none" }}
                      severity="error"
                    >
                      {error}
                    </Alert>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {data ? "Update" : "Add"}
                    </Button>
                  </Grid>
                </Grid>
              </FormContainer>
            </React.Fragment>
          </Paper>
        </Container>
      </Box>
    </>
  );
}
