import { Alert, Paper } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import React, { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui"
import { Link as RouterLink } from "react-router-dom"
import { useNavigate } from "react-router-dom"

import ForghettiLogo from "../Components/Common/ForghettiLogo"
import LoadingScreenBackdrop from "../Components/Common/LoadingScreenBackdrop"
import { auth, logInWithEmailAndPassword } from "../api/auth"

function Login() {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  const { setValue, getValues, control } = useForm()

  useEffect(() => {
    if (window["forghetti_SI4fkVkHjS4KT6Er8dmM"] && window["forghetti_SI4fkVkHjS4KT6Er8dmM"].state !== "not_running") {}
    console.log("user")
    window["forghetti_SI4fkVkHjS4KT6Er8dmM"].registerCallbacks({
      onPassword: function (data) {

        let field;
        if (data.email && data.username) {
          data.email !== "" ? field = data.email : field = data.username
        } else if (data.email) {
          field = data.email
        }
        else if (data.username) {
          field = data.username
        }

        setValue("email", field)

        setValue("password", data.password)
      },
      onGenerateLink: function (data) {
        console.log("onGenerateLink", data)
      }
    })

    window["forghetti_SI4fkVkHjS4KT6Er8dmM"].init({ doMyOwnInjection: true })
  

  }, [setValue])

  const [showError, setShowError] = useState(false)
  const [error, setError] = useState("")

  const login = async () => {
    
    const data=getValues()
    try {
      await logInWithEmailAndPassword(data.email, data.password)
    } catch (err) {
      setShowError(true)
      setError("Your credentials are invalid")
    }
  }
  useEffect(() => {
    if (loading) return
    if (user) navigate("/")
  }, [user, loading, navigate])

  const clearError = () => {
    setShowError(false)
    setError("")
  }

  return (
    <Container component="main" maxWidth="xs">
      <LoadingScreenBackdrop open={loading} />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}>
        <Paper sx={{ padding: 4 }}>
          <ForghettiLogo />

          <Typography
            align="center"
            mt={3}
            component="h1"
            variant="h5"
            sx={{
              justifyContent: "center",
              textAlign: "center",
              color: "text.secondary"
            }}>
            Partner Login
          </Typography>

          <FormContainer
            defaultValues={{ companyName: "", email: "", password: "" }}
            onSuccess={login}>
            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12}>
                <TextFieldElement
                  required
                  control={control}
                  fullWidth
                  id="email1"
                  type="email"
                  label="Email Address"
                  name="email"
                  autoComplete="off"
                  onChange={(event) => {
                    const value = getValues("email")
                    clearError()
                    setValue("email", value)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldElement
                  required
                  fullWidth
                  control={control}
                  name="password"
                  label="Password"
                  type="password"
                  id="password1"
                  autoComplete="off"
                  onChange={(event) => {
                    const value = getValues("password")
                    clearError()
                    setValue("password", value)
                  }}
                />
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link
                    component={RouterLink}
                    to="/reset"
                    variant="body2"
                    sx={{ color: "primary.dark" }}>
                    Forgotten Password
                  </Link>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Alert sx={{ display: !showError && "none" }} severity="error">
                  {error}
                </Alert>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "primary" }}>
                LOGIN
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Link
                component={RouterLink}
                to="/register"
                variant="body2"
                sx={{ color: "primary.dark" }}>
                Need an account? Register
              </Link>
            </Grid>
          </FormContainer>
        </Paper>
      </Grid>
      <div
        data-start="launch-forghetti"
        data-custom-initialise="true"
        id="forghetti-SI4fkVkHjS4KT6Er8dmM" ></div>
    </Container>
  )
}
export default Login
