import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import { FormContainer, TextFieldElement } from "react-hook-form-mui";

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, sendPasswordReset } from "../api/auth";

import { Alert, Paper } from "@mui/material";
import LoadingScreenBackdrop from "../Components/Common/LoadingScreenBackdrop";
import ForghettiLogo from "../Components/Common/ForghettiLogo";
import { maxWidth } from "@mui/system";

function ResetPassword() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

  const reset = async (data) => {
    try {
      debugger;
      await sendPasswordReset(data.email);
      navigate("/login");
    } catch (err) {
      setShowError(true);
      setError("Could not execute reset");
    }
  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading, navigate]);

  const clearError = () => {
    setShowError(false);
    setError("");
  };

  return (
    <Container component="main" minWidth="xs">
      <LoadingScreenBackdrop open={loading} />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper
          sx={{
            padding: 4,
          }}
        >
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <ForghettiLogo />
            </Grid>
            <Grid item xs={12}>
              <Typography
                align="center"
                mt={3}
                component="h1"
                variant="h5"
                sx={{
                  justifyContent: "center",
                  textAlign: "center",
                  color: "text.secondary",
                }}
              >
                Password Reset
              </Typography>

              <FormContainer
                defaultValues={{ companyName: "", email: "", password: "" }}
                onSuccess={reset}
              >
                <Grid item xs={12} sx={{ mt: 3}}>
                  <TextFieldElement
                    required
                    fullWidth
                    id="email1"
                    label="Email Address"
                    name="email"
                    autoComplete="new-password"
                    onChange={clearError}
                  />
                </Grid>

                <Grid hidden={!showError} item xs={12}>
                  <Alert
                    sx={{ display: !showError && "none" }}
                    severity="error"
                  >
                    {error}
                  </Alert>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "primary" }}
                  >
                    SEND PASSWORD RESET LINK
                  </Button>
                </Grid>

                <Grid item>
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="body2"
                    sx={{ color: "primary.dark" }}
                  >
                    Need an account? Register
                  </Link>
                </Grid>
              </FormContainer>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Container>
  );
}
export default ResetPassword;
