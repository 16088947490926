import { Alert, Box, Grid, Paper, Typography } from "@mui/material";
import * as React from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import BasicDOMInfo from "./BasicDOMInfo";
import HooksTabPanel from "./HooksTabPanel";
import JavascriptHookInfo from "./JavascriptHookInfo";

export default function ScriptBasicHelperInfo() {
  const [showHook, setShowHook] = React.useState(false);

  return (
    <>
      <Grid
        item
        xs={12}
        justifyContent={"flex-end"}
        mt={2}
        sx={{ width: "100%" }}
        onClick={() => {
          setShowHook(!showHook);
        }}
      >
        <Alert
          
          severity="info"
          color="secondary"
          sx={{ ml: 0, pl: 1, borderBottomRightRadius: showHook?  0 : 2, borderBottomLeftRadius: showHook?  0 : 2}}

        >
          Click here to learn how to set your DOM selector
        </Alert>
      </Grid>

      {showHook && (
        <Grid container >
          <Grid item xs={12}>
            <Box sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
              <Grid container maxWidth="sm">
                <Paper
                  sx={{
                    padding: 2,
                    zIndex: 1000,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                  }}
                  elevation={2}
                >

                  <BasicDOMInfo/>

                </Paper>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
